import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

import { getGroupName, getSubGroupName } from "../common/groups";
import { deepClone, ensureIntOrThrow, nullOrWhitespaceTrim, shieldException, throwError, useRefreshOnChange } from "../utils";
import * as routes from "../routes";
import * as entities from '../common/entities';
import { allLanguages } from "../common/language";
import * as rules from "../common/itemRules";

import { useSettings } from "../providers/SettingsContext";
import { useLayout } from "./Layout";
import { usePublicationsCache } from "../providers/PublicationsCacheContext";
import { useLanguage } from "../providers/LanguageContext";
import { useDialog } from "../providers/Dialogs";

import * as icons from "../media/icons";
import BackButton from "../components/BackButton";
import SubGroupSelection from "../components/SubGroupSelection";
import { InputPrice } from "../components/inputs";
import Translatable from "../components/Translatable";

function Reference() {
	const navigate = useNavigate();
	const { pubId, itemNumber } = useParams<{ pubId: string, itemNumber: string }>();

	const { newLog, hasAccess } = useSettings();
	const { language } = useLanguage();
	const { setHeaderPlaceholder: setHeaderButtons } = useLayout();
	const { confirm } = useDialog();

	const log = newLog('reference');
	const publicationsCache = usePublicationsCache();
	const refreshDependency = useRefreshOnChange(publicationsCache.changed);
	const [pageData, setPageData] = useState<null | 'error' | { publication: entities.Publication, reference: entities.Reference, state: rules.ReferenceState }>(null);

	const { current: emptyReference } = useRef<entities.Reference>({ publicationId: 0, itemNumber: '', prevItemNumber: null, name: { fr: '', nl: '' }, price: 0 })
	const origReference = (pageData === null || pageData === 'error') ? emptyReference : pageData.reference;
	const [pageState, setPageState] = useState(origReference);
	const readOnly = (!hasAccess('reference_manage'));
	const isModified = (JSON.stringify(pageState) !== JSON.stringify(origReference));

	function resetState() {
		setPageState(
			(pageData === null) || (pageData === 'error')
				? emptyReference
				: deepClone(pageData.reference));
	}
	useEffect(() => setPageData(null), [pubId, itemNumber]);  // reset `pageData` whenever selected reference changes
	useEffect(resetState, [pageData, emptyReference]);  // reset `pageState` whenever `pageData` changes

	// load page data (& reload whenever `pubId` and `itemNumber` parameters changes or `publicationsCache` notifies a change)
	useEffect(() => {
		shieldException(newLog('loadPageData'), async (log) => {
			const publicationId = ensureIntOrThrow({ n: pubId, log, errorMsg: `Invalid publication id specified` });
			const { publication, references } = await publicationsCache.get({ log, publicationId });
			const reference = references.find((v) => v.itemNumber === itemNumber) ?? throwError(`Unable to find reference '${itemNumber}'`);
			const state = new rules.ReferenceState(reference);
			return {
				publication,
				reference,
				state,
			};
		}).then(v => setPageData(v));
	}, [pubId, itemNumber, publicationsCache, refreshDependency, newLog]);

	// place action buttons in the layout's header
	const [headerButtonsProps,] = useState(() => ({
		handleSave: () => { },
		handleDelete: () => { },
		handleCancel: () => { },
	}));
	useEffect(() => {
		setHeaderButtons(
			ReferenceButtons({
				readOnly,
				isModified,
				...headerButtonsProps,
			})
		);
		// restore layout's header at umount
		return () => setHeaderButtons(null);
	}, [readOnly, isModified, setHeaderButtons, headerButtonsProps]);

	if (pageData == null)
		// Page not ready to display
		return <icons.LoadingSpinnerPage />;
	if (pageData === 'error')
		// Page failed to load
		return <icons.LoadingError />;
	const { publication, state } = pageData;

	function handleFormChange(cb: (r: entities.Reference) => void) {
		cb(pageState);
		setPageState({ ...pageState });  //nb: update reference to trigger dependencies
	}

	headerButtonsProps.handleCancel = function () {
		resetState();
	}

	headerButtonsProps.handleSave = function () {
		shieldException(log.child('save'),
			async (log) => {
				const publicationId = publication.publicationId;
				const itemNumber = origReference.itemNumber;
				const newItemNumber = pageState.itemNumber;
				await publicationsCache.update({  // nb: cache update => rerender => pageData update => form reset
					log,
					references: [{
						publicationId,
						itemNumber,
						reference: pageState,
					}],
				});
				navigate(routes.reference(publicationId, newItemNumber), { replace: true });  // nb: in case `itemNumber` has changed
			});
	}

	headerButtonsProps.handleDelete = function () {
		shieldException(log.child('delete'),
			async (log) => {
				const confirmed = await confirm({
					message: {
						fr: 'Êtes-vous sûr de vouloir supprimer cet enregistrement ?',
						nl: 'Bent u zeker dat u dit record wilt verwijderen?',
					},
				});
				if (!confirmed)
					return;
				const publicationId = publication.publicationId;
				const itemNumber = origReference.itemNumber;
				await publicationsCache.update({  // nb: cache update => rerender => pageData update => form reset
					log,
					deleteRefs: [{
						publicationId,
						itemNumber,
					}],
				});
				navigate(-1);  // Go back
			});
	}

	return <Container
		className="mt-3"
	>
		<Form.Group as={Row}>
			<Form.Label column className="col-3 text-nowrap">
				<Translatable>{{
					fr: 'Publication:',
					nl: 'Publicatie:',
				}}</Translatable>
			</Form.Label>
			<Col>
				<Form.Control
					plaintext
					readOnly
					value={`${publication.date} (${publication.publicationId})`}
				/>
			</Col>
		</Form.Group>

		<Form.Group as={Row}>
			<Form.Label column className="col-3 text-nowrap">
				<Translatable>{{
					fr: 'Numéro d\'article:',
					nl: 'Artikelnummer:',
				}}</Translatable>
			</Form.Label>
			<Col>
				<Form.Control
					plaintext={readOnly}
					readOnly={readOnly}
					value={pageState.itemNumber ?? ''}
					onChange={(e) => setPageState({ ...pageState, itemNumber: e.target.value })}
				/>
			</Col>

			<Form.Label column className="col-2 text-end text-nowrap">
				{((publication.prevId != null) && (pageState.prevItemNumber != null)) ?
					<Link
						to={routes.reference(publication.prevId, pageState.prevItemNumber)}
						style={{ color: 'inherit' }}
					>
						<icons.PageLinkOther />
						<Translatable>{{
							fr: 'précédent:',
							nl: 'vorige:',
						}}</Translatable>
					</Link>
					: readOnly ?
						<></>
						:
						<Translatable>{{
							fr: 'précédent:',
							nl: 'vorige:',
						}}</Translatable>
				}
			</Form.Label>
			<Col>
				<Form.Control
					plaintext={readOnly}
					readOnly={readOnly}
					value={pageState.prevItemNumber ?? ''}
					onChange={(e) => handleFormChange(r => r.prevItemNumber = nullOrWhitespaceTrim(e.target.value))}
				/>
			</Col>
		</Form.Group>

		{allLanguages.map(lang =>
			<Form.Group as={Row} key={lang} className="mt-3">
				<Form.Label column className="col-3 text-nowrap">
					<Translatable>{{
						fr: `Nom (${lang}):`,
						nl: `Naam (${lang}):`,
					}}</Translatable>
				</Form.Label>
				<Col>
					<Form.Control
						plaintext={readOnly}
						readOnly={readOnly}
						value={pageState.name[lang] ?? ''}
						onChange={(e) => handleFormChange(r => r.name[lang] = e.target.value)}
					/>
				</Col>
			</Form.Group>
		)}

		<Form.Group as={Row} className="mt-3">
			<Form.Label column className="col-3 text-nowrap">
				<Translatable>{{
					fr: 'Prix:',
					nl: 'Prijs:',
				}}</Translatable>
			</Form.Label>
			<Col>
				<InputPrice
					className={`${readOnly ? 'form-control-plaintext' : 'form-control'}`}
					readOnly={readOnly}
					initialValue={pageState.price}
					onChange={(v) => handleFormChange(r => r.price = v ?? 0)}
				/>
			</Col>
		</Form.Group>

		<Form.Group as={Row} className="mt-3">
			<Form.Label column className="col-3 text-nowrap">
				<Translatable>{{
					fr: 'Groupe:',
					nl: 'Groep:',
				}}</Translatable>
			</Form.Label>
			<Col as={InputGroup}>
				{readOnly && (state.item.subGroup != null) &&
					<Form.Control
						plaintext
						readOnly
						value={`${state.item.subGroup}: ${getGroupName(state.item.subGroup, language)} | ${getSubGroupName(state.item.subGroup, language)}`}
					/>
				}
				{readOnly ||
					<SubGroupSelection
						initial={pageState.subGroup ?? null}
						onChange={(g) => handleFormChange(r => r.subGroup = g)}
					/>
				}
			</Col>
		</Form.Group>

		{readOnly ||  // nb: do not show errors to regular users
			state.errors.map((err) =>
				<div key={err} className="alert alert-danger" role="alert">
					{rules.ruleErrorTexts[err][language]}
				</div>)}
	</Container>
}

function ReferenceButtons({ readOnly, isModified, handleSave, handleDelete, handleCancel }: {
	readOnly: boolean,
	isModified: boolean,
	handleSave: () => void,
	handleDelete: () => void,
	handleCancel: () => void,
}) {
	const showSave = (!readOnly) && isModified;
	const showDelete = (!readOnly);
	const showCancel = (!readOnly) && isModified;

	return <span>
		<BackButton />

		{showSave &&
			<button type="submit" className="btn btn-primary ms-1" onClick={handleSave}>
				<Translatable>{{
					fr: `Enregistrer`,
					nl: `Opslaan`,
				}}</Translatable>
			</button>
		}

		{showDelete &&
			<button type="submit" className="btn btn-primary ms-1" onClick={() => handleDelete()}>
				<Translatable>{{
					fr: `Supprimer`,
					nl: `Verwijderen`,
				}}</Translatable>
			</button>
		}

		{showCancel &&
			<button type="submit" className="btn btn-secondary ms-1" onClick={handleCancel}>
				<Translatable>{{
					fr: `Annuler`,
					nl: `Annuleren`,
				}}</Translatable>
			</button>
		}
	</span>
}

export default Reference;
