import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import { DropDirection } from "react-bootstrap/esm/DropdownContext";

import { Language, allLanguages, languageNames } from "../common/language";
import { useLanguage } from "../providers/LanguageContext";

import * as icons from "../media/icons";

function LanguageSelection(p:
	({ setGlobal: true } & Parameters<typeof GlobalSelection>[0]) |
	({ setGlobal?: false | undefined } & Parameters<typeof Selection>[0])) {
	return p.setGlobal
		? GlobalSelection(p)
		: Selection(p);
}

function GlobalSelection(p: {
	className?: string,
	drop?: DropDirection,
}) {
	const { language, setLanguage } = useLanguage();
	return Selection({ ...p, value: language, onChanged: setLanguage });
}

function Selection({ value: language, onChanged, className, drop }: {
	value: Language,
	onChanged: (l: Language) => void,
	className?: string,
	drop?: DropDirection,
}) {
	return (
		<Dropdown
			className={className}
			drop={drop}
		>
			<Dropdown.Toggle
				as={Nav.Link}
			>
				<icons.Flag language={language} /> {languageNames[language]}
			</Dropdown.Toggle>

			<Dropdown.Menu
				style={{ position: 'absolute' }}  // Hack to force floating when used in the "mobile's menu"
			>
				{allLanguages.map((lang) => {
					return (
						<Dropdown.Item
							key={lang}
							href="#/"
							onClick={() => onChanged(lang)}
						>
							<icons.Flag language={lang} /> {languageNames[lang]}
						</Dropdown.Item>
					)
				})}
			</Dropdown.Menu>

		</Dropdown>
	)
}

export default LanguageSelection;
