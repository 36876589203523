import Log from "./logger";
import * as utils from "./utils";

import { Publication, Reference } from "./entities";
import PublicationsStore from "./publicationsStore";
import ReferencesStore from "./referencesStore";

export class PublicationsCache {
	private readonly cache: {
		[publicationId: number]: {
			readonly changed: utils.UpdatedEventEmitter,
			value: null | {
				publication: Publication,
				references: Reference[],
			},
			task: null | ReturnType<PublicationsCache['fetch']>,
		}
	} = {};
	public readonly changed = new utils.UpdatedEventEmitter();

	public get fetchCount() { return this.fetchCount_; }
	private fetchCount_ = 0;

	constructor(
		public readonly pubsStore: PublicationsStore,
		public readonly refsStore: ReferencesStore,
	) {
	}

	public clearAll() {
		for (const id in this.cache)
			this.clear({ publicationId: Number(id) });
	}

	public clear({ publicationId }: {
		publicationId: number,
	}) {
		const entry = this.cache[publicationId];
		if (entry == null)
			// not cached
			return;
		if ((entry.value === null) && (entry.task === null))
			// already cleared
			return;

		entry.value = null;
		entry.task = null;
		entry.changed.trigger();
	}

	public get({ log, publicationId, forceFetch = false }: {
		log: Log,
		publicationId: number,
		forceFetch?: boolean,
	}) {
		const entry = this.getEntry(publicationId);

		const value = forceFetch ? null : entry.value;
		if (value) {
			// available and not forced => resolve synchroneously
			return Promise.resolve({
				...value,
				changed: entry.changed,
			});
		}

		const task = forceFetch ? null : entry.task;
		return (async () => {
			// return already running fetch or launch a new one
			const value = await (task ?? this.queueFetch({ log, publicationId, entry }));
			return {
				...value,
				changed: entry.changed,
			};
		})();
	}

	public tryGet({ log, publicationId, fetchIfUnavailable = false }: {
		log: Log,
		publicationId: number,
		fetchIfUnavailable?: boolean,
	}) {
		const entry = this.getEntry(publicationId);

		if (fetchIfUnavailable && (entry.value === null)) {
			// need to fetch
			if (entry.task === null)
				// no fetch currently running => launch a new one
				this.queueFetch({ log, publicationId, entry })
		}

		return {
			publication: entry.value?.publication ?? null,
			references: entry.value?.references ?? null,
			changed: entry.changed,
		};
	}

	public async update({ log, publications = [], references = [], deleteRefs = [] }: {
		log: Log,
		publications?: (Parameters<PublicationsStore['update']>[0]['publication'])[],
		references?: Parameters<ReferencesStore['update']>[0]['items'],
		deleteRefs?: Parameters<ReferencesStore['delete']>[0]['items'],
	}) {
		const updatedPublicationIds = new Set<number>();

		const updateTasks = publications.map(async (publication) => {
			// update publication
			const updated = await this.pubsStore.update({ log: log.child(`pub-${publication.publicationId}`), publication });

			const cached = this.cache[publication.publicationId]?.value;
			if (cached == null)
				// this entry is not cached (yet?)
				return;
			// update cached instance
			cached.publication = updated;

			updatedPublicationIds.add(publication.publicationId);
		});

		if (references.length > 0) {
			updateTasks.push((async (log) => {
				// update references
				const updatedRefs = await this.refsStore.update({ log, items: references });

				references.forEach((item, i) => {
					const updatedRef = updatedRefs[i];  // nb: 'references' and 'updatedRefs' arrays are supposed to have the same size

					const cached = this.cache[updatedRef.publicationId]?.value;
					if (cached == null)
						// this entry is not cached (yet?)
						return;

					// replace the reference instance in the cache
					const itemNumber = item.itemNumber ?? updatedRef.itemNumber;
					const j = cached.references.findIndex((v) => v.itemNumber === itemNumber);
					if (j < 0) {  // nb: updated an item that is not in the cache? not supposed to happen ...
						log.error(`Could not find updated reference '${updatedRef.publicationId}-${itemNumber}' in the cache`);
						return;
					}
					cached.references[j] = updatedRef;

					updatedPublicationIds.add(updatedRef.publicationId);
				});
			})(log.child(`update-refs`)));
		}

		if (deleteRefs.length > 0) {
			updateTasks.push((async (log) => {
				// delete references
				await this.refsStore.delete({ log, items: deleteRefs });

				deleteRefs.forEach(({ publicationId, itemNumber }) => {
					const cached = this.cache[publicationId]?.value;
					if (cached == null)
						// this entry is not cached (yet?)
						return;

					// remove cached instances
					const i = cached.references.findIndex((v) => v.itemNumber === itemNumber);
					if (i < 0) {  // nb: updated an item that is not in the cache? not supposed to happen ...
						log.error(`Could not find updated reference '${publicationId}-${itemNumber}' in the cache`);
						return;
					}
					cached.references.splice(i, 1);

					updatedPublicationIds.add(publicationId);
				});

			})(log.child(`delete-refs`)));
		}

		// wait for update tasks
		await Promise.all(updateTasks.concat(updateTasks));

		updatedPublicationIds.forEach((publicationId) => {
			// notify changed publications
			const entry = this.cache[publicationId];
			entry.changed.trigger();

			// launch refresh in background ; ie. only to stay on the safe side ; should not be necessary, cached objects should already be up to date
			this.queueFetch({ log: log.child('refresh'), publicationId, entry });
		});
	}

	private getEntry(publicationId: number) {
		const existing = this.cache[publicationId];
		if (existing)
			return existing;

		const created = {
			changed: new utils.UpdatedEventEmitter(`${publicationId}-`),
			value: null,
			task: null,
		};
		created.changed.subscribe(() => this.changed.trigger());
		this.cache[publicationId] = created;

		return created;
	}

	private queueFetch({ log, publicationId, entry }: {
		log: Log,
		publicationId: number,
		entry: Awaited<PublicationsCache['cache'][0]>,
	}) {
		const task = this.fetch({ log, publicationId });
		entry.task = task;

		task.then((value) => {
			if (entry.task !== task)
				// another fetch has been scheduled in the mean time => ignore this run
				return;

			entry.value = value;
			entry.task = null;
			entry.changed.trigger();
		});

		return task;
	}

	private async fetch({ log, publicationId }: {
		log: Log,
		publicationId: number,
	}) {
		log = log.child('pubcache');
		let allOk = false;
		try {
			++this.fetchCount_;
			this.changed.trigger();

			const pubTask = this.pubsStore.get({ log, publicationId });
			const refsTask = this.refsStore.get({ log, publicationId });

			const value = {
				publication: (await pubTask)[0] ?? utils.throwError(`Publication ${publicationId} not found`, log),
				references: await refsTask,
			};

			allOk = true;
			return value;
		}
		finally {
			--this.fetchCount_;
			this.changed.trigger();

			if (!allOk) {
				// something went wrong (ie. exception) => clear cache so next fetch retries
				log.warning(`fetch failed => clear cache entry for publicationId '${publicationId}'`);
				this.clear({ publicationId });
			}
		}
	}
}

export default PublicationsCache;
