import { useEffect, useState } from "react";

import { Language } from "../common/language";
import { Group, SubGroup, allGroups, allSubGroups, getGroup, groupNames, subGroupNames } from "../common/groups";
import { useLanguage } from "../providers/LanguageContext";
import { InputText } from "./inputs";
import { nullOrWhitespaceTrim } from "../common/utils";

export function SubGroupSelection({ initial, onChange }: {
	initial: SubGroup | null,
	onChange: (grp: SubGroup | null) => void,
}) {
	const { language: lang } = useLanguage();
	const [currentGroup, setCurrentGroup] = useState(getGroup(initial));
	const [currentSubGroup, setCurrentSubGroup] = useState(initial);

	const [availableGroup, setAvailableGroup] = useState(allGroups.map(g => ({ code: g, label: groupNames[g][lang] })));
	const [availableSubGroup, setAvailableSubGroup] = useState(getAvailableSubGroups(currentGroup, lang));

	// Restore inital values
	useEffect(() => {
		setCurrentGroup(getGroup(initial));
		setCurrentSubGroup(initial);
	}, [initial, onChange]);

	// Update available groups on language change
	useEffect(() => {
		const l = allGroups.map(g => ({ code: g, label: groupNames[g][lang] }));
		setAvailableGroup(l);
	}, [lang]);

	// Update available subGroups on group or language change
	useEffect(() => {
		const l = getAvailableSubGroups(currentGroup, lang);
		setAvailableSubGroup(l);
	}, [currentGroup, lang])

	function setValues(group: Group | null, subGroup: SubGroup | null) {
		setCurrentGroup(group);
		setCurrentSubGroup(subGroup);
		if (currentSubGroup !== subGroup)
			onChange(subGroup);
	}

	function handleGroupChange(str: string | null) {
		str = nullOrWhitespaceTrim(str)?.toUpperCase() ?? null;
		const newGroup = allGroups.find(v => v.toUpperCase() === str) ?? null;

		if (newGroup == null) {
			setValues(null, null);
		}
		else if (newGroup !== currentGroup) {
			// Select first available subgroup
			const l = getAvailableSubGroups(newGroup, lang);
			const newSubGroup = l[0].code;
			setValues(newGroup, newSubGroup);
		}
	}

	function handleSubGroupChange(str: string | null) {
		str = nullOrWhitespaceTrim(str)?.toLowerCase() ?? null;
		const newSubGroup = allSubGroups.find(v => v.toLowerCase() === str) ?? null;
		const newGroup = getGroup(newSubGroup);
		setValues(newGroup, newSubGroup);
	}

	return <>
		<select
			value={currentGroup ?? ''}
			onChange={e => handleGroupChange(e.target.value)}
			className="form-control"
		>
			<option value=""></option>
			{availableGroup.map(g => <option key={g.code} value={g.code}>{`${g.code}: ${g.label}`}</option>)}
		</select>
		<select
			value={currentSubGroup ?? ''}
			onChange={e => handleSubGroupChange(e.target.value)}
			className="form-control"
		>
			<option value=""></option>
			{availableSubGroup.map(g => <option key={g.code} value={g.code}>{`${g.code}: ${g.label}`}</option>)}
		</select>
		<InputText
			initialValue={currentSubGroup ?? ''}
			onChange={handleSubGroupChange}
			className="form-control"
		/>
	</>;
};

function getAvailableSubGroups(group: Group | null, lang: Language): { code: SubGroup, label: string }[] {
	if (group == null)
		return [];
	const subGroups = allSubGroups.filter(s => getGroup(s) === group);
	return subGroups.map(g => ({ code: g, label: subGroupNames[g][lang] }));
}

export default SubGroupSelection;
