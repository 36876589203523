import { createContext, ReactNode, useContext, useState } from "react";

import * as utils from "../utils";

import PublicationsCache from "../common/publicationsCache";
import { usePublicationsSource, useReferencesSource } from "./DataSources";

const PublicationsCacheContext = createContext<null | PublicationsCache>(null);

export function PublicationsCacheProvider({ children }: {
	children: ReactNode,
}) {
	const pubsStore = usePublicationsSource();
	const refsStore = useReferencesSource();
	const [cache,] = useState(new PublicationsCache(pubsStore, refsStore));

	return (
		<PublicationsCacheContext.Provider value={cache}>
			{children}
		</PublicationsCacheContext.Provider>
	);
}

export const usePublicationsCache = () => useContext(PublicationsCacheContext) ?? utils.throwError(`'usePublicationsCache()' must be used within a 'PublicationsCacheProvider'`);
