import { useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import * as routes from "../routes";
import * as utils from "../utils";

import { Login } from "../media/icons";
import Translatable from "../components/Translatable";
import { useSettings } from "../providers/SettingsContext";

function Home() {
	const { settings, hasAccess } = useSettings();

	if (!settings.loggedIn)
		return <LoggedOut />

	const showTenantSelector = hasAccess('tenants_manageall')
		|| (hasAccess('tenants_use') && (settings.availableTenants.length > 1));

	return <Container className='mt-5'>

		{showTenantSelector &&
			<TenantSelector />
		}

	</Container>
}

function LoggedOut() {
	const loginUrl = routes.api.auth.login(routes.adjustment);

	return <Container className='text-center mt-5'>
		<a href={loginUrl} className='nav-link'>
			<Login size='10em' />
			<br />
			Login
		</a>
	</Container>
}

function TenantSelector() {
	const { settings, hasAccess, newLog, refreshSettings } = useSettings();
	const [allTenants, setAllTenants] = useState<null | string[]>(null);
	const { current: s } = useRef({
		idShowAll: utils.generateId(),
	});

	const currentTenant = settings.tenant!;
	const canViewAllTenants = hasAccess('tenants_manageall');
	const tenantsList = (allTenants ?? settings.availableTenants).sort();

	if (tenantsList.indexOf(currentTenant) < 0) {
		// current tenant not in the list (eg. unchecked "view all" but was on an inaccessible one)
		// => add it to the list of visible ones
		tenantsList.unshift(currentTenant);
	}

	async function handleShowAll(value: boolean) {
		const log = newLog('handleShowAll');
		try {
			if (value === false) {
				// clear "all" list => will show only available
				setAllTenants(null);
				return;
			}

			log.log(`Retreive list of all tenants`);
			const { tenants } = await utils.apiRequest<routes.api.general.GetAllTenantsResult>({
				method: 'GET',
				url: routes.api.general.getAllTenants(),
				exceptionMessage: 'Unable to set current publication'
			});
			setAllTenants(tenants);
		} catch (ex) {
			log.error(`Error retrieving tenant list`);
			log.exception(ex);
		}
	}

	async function handleSelectTenant(tenant: string) {
		const log = newLog('handleSelectTenant');
		try {
			log.log(`Switching to tenant '${tenant}'`);
			const body: routes.api.general.UseTenantRequest = { tenant };
			await utils.apiRequest<routes.api.general.UseTenantResult>({
				method: 'POST',
				url: routes.api.general.useTenant(),
				exceptionMessage: 'Unable to set current publication',
				body,
			});

			log.log(`Refreshing user's settings`);
			await refreshSettings();
		} catch (ex) {
			log.error(`Error switching tenant`);
			log.exception(ex);
		}
	}

	return <>
		<Form.Group as={Row}>
			<Form.Label column className="col-2">
				<Translatable>{{
					fr: `Filiale:`,
					nl: `Filiaal:`,
				}}</Translatable>
			</Form.Label>
			<Col>
				<Form.Select
					value={currentTenant}
					onChange={(evt) => handleSelectTenant(evt.target.value)}
				>
					{tenantsList.map((tenant) => (
						<option key={tenant} value={tenant}>{tenant}</option>
					))}
				</Form.Select>
			</Col>
			{canViewAllTenants &&
				<Col className="col-1">
					<Form.Label column>
						<Form.Check type="checkbox"
							id={s.idShowAll}
							checked={allTenants != null}
							onChange={(e) => handleShowAll(e.target.checked)}
							className="col-4"
							label={<Translatable>{{
								fr: 'toutes',
								nl: 'alle',
							}}</Translatable>}
						/>
					</Form.Label>
				</Col>
			}
		</Form.Group>
	</>
}

export default Home;
