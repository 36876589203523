import React, { CSSProperties, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fr } from "date-fns/locale/fr";
import { nlBE } from "date-fns/locale/nl-BE";

import { allLanguages, Language } from "../common/language";
import { dateString, dateValue, generateId, nullOrWhitespaceTrim, stringFilterMatches } from "../common/utils";
import * as rules from "../common/itemRules";

import * as icons from "../media/icons";
import Translatable from "./Translatable";
import { useLanguage } from "../providers/LanguageContext";

// ReactDatePicker: register locales for all languages
allLanguages.forEach((lang) => {
	const dict: { [key in Language]: typeof fr } = {
		'fr': fr,
		'nl': nlBE,
	};
	registerLocale(lang, dict[lang]);
});

/** Simple text input that triggers 'onChange()' only when either the focus is lost or the 'Enter' key is pressed */
export function InputText({ initialValue, onChange, id, className, style }: {
	initialValue: string,
	onChange: (str: string) => void,
	id?: string,
	className?: string,
	style?: CSSProperties,
}) {
	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return <input type="text"
		id={id}
		className={className}
		style={style}
		value={value}
		onChange={(e) => setValue(e.target.value)}
		onBlur={() => onChange(value)}
		onKeyDown={(e) => { if (e.key === 'Enter') onChange(value); }}
	/>
}

/** Simple number input with 2 decimals that triggers 'onChange()' only when either the focus is lost or the 'Enter' key is pressed */
export function InputPrice({ initialValue, onChange, id, className, readOnly }: {
	initialValue: number | null,
	onChange: (price: number | null) => void,
	id?: string,
	className?: string,
	readOnly?: boolean,
}) {
	const [value, setValue] = useState(getString(initialValue));

	useEffect(() => {
		setValue(getString(initialValue));
	}, [initialValue]);

	return <input type="number"
		id={id}
		className={className}
		readOnly={readOnly}
		step="0.01"
		value={value}
		onChange={(e) => setValue(e.target.value)}
		onBlur={() => onChange(getNumber(value))}
		onKeyDown={(e) => { if (e.key === 'Enter') onChange(getNumber(value)); }}
	/>
}
function getString(n: number | null): string {
	if (n == null)
		return '';
	return n.toString();
}
function getNumber(str: string): number | null {
	const s = nullOrWhitespaceTrim(str);
	if (s == null)
		return null;
	const n = parseFloat(s);
	if (isNaN(n))
		return null;
	return n;
}

export function InputCheckbox({ id, className = 'form-check-input form-check-input-info', checked, onChange }: {
	id?: string,
	className?: string,
	checked?: boolean,
	onChange?: (v: boolean) => void,
}) {
	return <input type="checkbox"
		id={id}
		className={className}
		checked={checked}
		onClick={(evt) => {
			evt.stopPropagation();
		}}
		onChange={onChange == null ? undefined : (evt) => {
			onChange(evt.target.checked);
		}}
	/>
}

export function InputTristate({ initialValue, onChange, allowNull = true, id, className = 'form-check-input form-check-input-info', readonly = false }: {
	initialValue: boolean | null,
	onChange?: (v: boolean | null) => void,
	allowNull?: boolean,
	id?: string,
	className?: string,
	readonly?: boolean,
}) {
	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	function handleClick() {
		if (readonly)
			return;

		const v =
			(value == null)
				? false
				: (value === false)
					? true
					: (allowNull ? null : false);
		setValue(v);
		if (onChange != null)
			onChange(v);
	}

	return (value == null) ? <span id={id} className={className} onClick={handleClick}><icons.Missing /></span>
		: <input type="checkbox"
			id={id}
			readOnly={readonly}
			tabIndex={readonly ? -1 : undefined}
			className={className}
			checked={value}
			onClick={(evt) => {
				evt.stopPropagation();
			}}
			onChange={handleClick}
		/>
}

export function InputAutoComplete<Code extends string>({ initialValue, options, onChange, className }: {
	initialValue: Code | null,
	options: { code: Code; label: string }[],
	onChange: (code: Code | null) => void,
	className?: string,
}) {
	const [inputValue, setInputValue] = useState<string>('');
	const [filteredOptions, setFilteredOptions] = useState(options);
	const [s] = useState({
		idDropDown: generateId(),
	});

	useEffect(() => {
		const initialLabel = options.find(v => v.code === initialValue)?.label || '';
		setInputValue(initialLabel);
	}, [initialValue, options]);

	useEffect(() => {
		const l = (nullOrWhitespaceTrim(inputValue) == null)
			? options
			: options.filter(v => stringFilterMatches(inputValue, `${v.code} ${v.label}`));
		setFilteredOptions(l);
	}, [options, inputValue]);

	function handleSelectOption(option: null | typeof options[0]) {
		setInputValue(option?.label ?? '');
		onChange(option?.code ?? null);
	};

	function handleInputValueChange() {
		if (nullOrWhitespaceTrim(inputValue) == null)
			handleSelectOption(null);
		else if (filteredOptions.length === 1)
			handleSelectOption(filteredOptions[0]);
	};

	return <>
		<input
			id={s.idDropDown}
			type="text"
			className={className}
			value={inputValue}
			onChange={e => setInputValue(e.target.value)}
			onBlur={(e) => handleInputValueChange()}
			onKeyDown={(e) => { if (e.key === 'Enter') handleInputValueChange(); }}
			data-bs-toggle="dropdown"
		/>
		<ul className="dropdown-menu" aria-labelledby={s.idDropDown}>
			{filteredOptions.map((option) => (
				<li key={option.code}>
					<div className="dropdown-item" onClick={() => handleSelectOption(option)} >
						{option.code}: {option.label}
					</div>
				</li>
			))}
		</ul>
	</>
}

export function DatePicker({ value: initial, allowNull = true, outerClassName, innerClassName, style, mode = 'default', onChange }: {
	outerClassName?: string,
	innerClassName?: string,
	style?: React.CSSProperties,
	mode?: 'default' | 'adjustment',
} & (
		{
			allowNull?: true,
			value?: Date | null,
			onChange?: (dt: Date | null) => void,
		} | {
			allowNull: false,
			value: Date,
			onChange?: (dt: Date) => void,
		}
	)) {
	const { language } = useLanguage();

	const today = new Date();
	const readOnly = (onChange == null);
	if ((!allowNull) && (initial == null))
		initial = today;
	const getDateString = (d: Date | null | undefined) => (d == null) ? '' : dateString({ d, withTime: false });

	const [strValue, setStrValue] = useState(getDateString(initial))
	useEffect(() => setStrValue(getDateString(initial)), [initial, setStrValue]);

	function handleTextBoxChange(str: string) {
		if (readOnly)
			return;
		setStrValue(str);
	}

	function handleTextboxBlur() {
		if (readOnly)
			return;
		if (strValue === '') {
			// textbox cleared
			if (allowNull)
				onChange(null!);
			else
				// reset to initial
				setStrValue(getDateString(initial));
			return;
		}
		const dt = dateValue({ str: strValue });
		if (dt == null) {
			// invalid date => reset to initial
			setStrValue(getDateString(initial));
			return;
		}
		onChange(dt);
	}

	function handlePickerChange(dt: Date | null) {
		if (readOnly)
			return;
		if ((dt == null) && (!allowNull))
			// reset to initial
			setStrValue(getDateString(initial));
		else
			onChange(dt!);
	}

	function handleDayButtonClicked(s: 'today' | 'expiration') {
		onChange!((s === 'today')
			? today
			: dateValue({ str: rules.getExpirationDateDefault() })!);
	}

	return <>
		<Dropdown
			className={outerClassName}
			style={style}
		>
			<Dropdown.Toggle as="input"
				value={strValue}
				className={innerClassName}
				readOnly={readOnly}
				onChange={(evt) => handleTextBoxChange((evt as any).target.value)}
				onBlur={() => handleTextboxBlur()}
				onKeyDown={(evt) => { if (evt.key === 'Enter') handleTextboxBlur(); }}
			>
			</Dropdown.Toggle>

			{readOnly ||
				<Dropdown.Menu
				>
					<ReactDatePicker
						selected={initial}
						onChange={handlePickerChange}
						dateFormat="yyyy-MM-dd"
						inline={true}
						locale={language}
					>
						<div style={{ textAlign: 'center' }}>
							<Button
								variant="secondary"
								size="sm"
								onClick={() => handleDayButtonClicked('today')}
							>
								<Translatable>{{
									'fr': 'Aujourd\'hui',
									'nl': 'Vandaag',
								}}</Translatable>
							</Button>
							{(mode === 'adjustment') &&
								<Button
									variant="secondary"
									size="sm"
									className="ms-1"
									onClick={() => handleDayButtonClicked('expiration')}
								>
									<Translatable>{{
										'fr': `+${rules.expirationDaysDefault} jours`,
										'nl': `+${rules.expirationDaysDefault} dagen`,
									}}</Translatable>
								</Button>
							}
						</div>
					</ReactDatePicker>
				</Dropdown.Menu>
			}
		</Dropdown>
	</>
}
