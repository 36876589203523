import { CSSProperties } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import { Language } from '../common/language';

import logo from './logo.svg';

// https://icons.getbootstrap.com/icons/arrow-left/
const arrowLeft = () =>
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
		<path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
	</svg>

// https://icons.getbootstrap.com/icons/check-circle-fill/
const checkCircleFill = ({ className, size = 16, style }: {
	className?: string,
	size?: number | string,
	style?: CSSProperties,
}) =>
	<svg xmlns="http://www.w3.org/2000/svg"
		className={className}
		style={style}
		fill="currentColor"
		width={size}
		height={size}
		viewBox="0 0 16 16"
	>
		<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
	</svg>

// minus sign
// https://icons.getbootstrap.com/icons/dash-lg/
const dashLg = () =>
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16">
		<path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8" />
	</svg>

// https://icons.getbootstrap.com/icons/emoji-dizzy/
const emojiDizzy = ({ className, size = 16, style }: {
	className?: string,
	size?: number | string,
	style?: CSSProperties,
}) =>
	<svg xmlns="http://www.w3.org/2000/svg"
		className={className}
		style={style}
		fill="currentColor"
		width={size}
		height={size}
		viewBox="0 0 16 16"
	>
		<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
		<path d="M9.146 5.146a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 1 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 0-.708m-5 0a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 1 1 .708.708l-.647.646.647.646a.5.5 0 1 1-.708.708L5.5 7.207l-.646.647a.5.5 0 1 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 0-.708M10 11a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
	</svg>

// https://icons.getbootstrap.com/icons/exclamation-circle-fill/
const exclamationCircleFill = ({ className, size = 16, style }: {
	className?: string,
	size?: number | string,
	style?: CSSProperties,
}) =>
	<svg xmlns="http://www.w3.org/2000/svg"
		className={className}
		style={style}
		fill="currentColor"
		width={size}
		height={size}
		viewBox="0 0 16 16"
	>
		<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
	</svg>

// https://github.com/lipis/flag-icons/blob/main/flags/1x1/fr.svg
const flagFR = ({ className, size = '16px' }: {
	className?: string,
	size?: string,
}) =>
	<svg xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		fill="currentColor"
		className={className}
		viewBox="0 0 512 512"
	>
		<path fill="#fff" d="M0 0h512v512H0z" />
		<path fill="#000091" d="M0 0h170.7v512H0z" />
		<path fill="#e1000f" d="M341.3 0H512v512H341.3z" />
	</svg>

// https://github.com/lipis/flag-icons/blob/main/flags/1x1/nl.svg
const flagNL = ({ className, size = '16px' }: {
	className?: string,
	size?: string,
}) =>
	<svg xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		fill="currentColor"
		className={className}
		viewBox="0 0 512 512"
	>
		<path fill="#21468b" d="M0 0h512v512H0z" />
		<path fill="#fff" d="M0 0h512v341.3H0z" />
		<path fill="#ae1c28" d="M0 0h512v170.7H0z" />
	</svg>

// https://icons.getbootstrap.com/icons/house-fill/
const houseFill = ({ className, size = '16px' }: {
	className?: string,
	size?: string,
}) =>
	<svg xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		fill="currentColor"
		className={className}
		viewBox="0 0 16 16"
	>
		<path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293z" />
		<path d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293z" />
	</svg>

// https://icons.getbootstrap.com/icons/lock-fill/
const lockFill = ({ className, size = '16px' }: {
	className?: string,
	size?: string,
}) =>
	<svg xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		fill="currentColor"
		className={className}
		viewBox="0 0 16 16"
	>
		<path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2" />
	</svg>

// https://icons.getbootstrap.com/icons/link-45deg/
const link45deg = ({ className, size = '16px' }: {
	className?: string,
	size?: string,
}) =>
	<svg xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		fill="currentColor"
		className={className}
		viewBox="0 0 16 16"
	>
		<path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z" />
		<path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z" />
	</svg>

// https://icons.getbootstrap.com/icons/moon-fill/
const moonFill = ({ className, size = '16px', style }: {
	className?: string,
	size?: string,
	style?: CSSProperties,
}) =>
	<svg xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		fill="currentColor"
		className={className}
		style={style}
		viewBox="0 0 16 16"
	>
		<path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278" />
	</svg>

// https://icons.getbootstrap.com/icons/pencil-fill/
const pencilFill = () =>
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
		<path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
	</svg>

// plus sign
// https://icons.getbootstrap.com/icons/plus-lg/
const plusLg = () =>
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
		<path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
	</svg>

// https://icons.getbootstrap.com/icons/question/
const question = ({ size = '16' }: {
	size?: string,
}) =>
	<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="currentColor" className="bi bi-question" viewBox="0 0 16 16">
		<path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
	</svg>

// https://icons.getbootstrap.com/icons/sun-fill/
const sunFill = ({ className, size = '16px', style }: {
	className?: string,
	size?: string,
	style?: CSSProperties,
}) =>
	<svg xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		fill="currentColor"
		className={className}
		style={style}
		viewBox="0 0 16 16"
	>
		<path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" />
	</svg>

// https://icons.getbootstrap.com/icons/trash-fill/
export const trashFill = ({ className, size = 16, style }: {
	className?: string,
	size?: number | string,
	style?: CSSProperties,
}) =>
	<svg xmlns="http://www.w3.org/2000/svg"
		className={className}
		style={style}
		fill="currentColor"
		width={size}
		height={size}
		viewBox="0 0 16 16"
	>
		<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
	</svg>

// https://icons.getbootstrap.com/icons/unlock-fill/
const unlockFill = ({ className, size = 16 }: {
	className?: string,
	size?: number | string,
}) =>
	<svg xmlns="http://www.w3.org/2000/svg"
		className={className}
		fill="currentColor"
		width={size}
		height={size}
		viewBox="0 0 16 16"
	>
		<path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2" />
	</svg>

// https://icons.getbootstrap.com/icons/x-circle-fill/
const xCircleFill = ({ className, size = 16, style }: {
	className?: string,
	size?: number | string,
	style?: CSSProperties,
}) =>
	<svg xmlns="http://www.w3.org/2000/svg"
		className={className}
		style={style}
		fill="currentColor"
		width={size}
		height={size}
		viewBox="0 0 16 16"
	>
		<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
	</svg>


////////////

export const Back = arrowLeft;
export const Error = exclamationCircleFill;
export const Flag = ({ language, className, size }: { language: Language, className?: string, size?: string }) => ({ fr: flagFR, nl: flagNL }[language]({ className, size }));
export const Home = houseFill;
export const ItemCancel = xCircleFill;
export const ItemDelete = trashFill;
export const ItemDetailsCollapse = dashLg;
export const ItemDetailsExpand = plusLg;
export const ItemEdited = pencilFill;
export const ItemSave = checkCircleFill;
export const LoadingError = () => emojiDizzy({ size: '5em', style: { color: 'red' } });
export const LoadingSpinnerButton = () => <Spinner className="align-middle" />
export const LoadingSpinnerPage = () => <Spinner className="m-5" style={{ height: '10em', width: '10em' }} />
export const Login = lockFill;
export const Logo = ({ className = 'img', style }: { className?: string, style?: CSSProperties }) => <img src={logo} className={className} style={style} alt="logo" />;
export const Logout = unlockFill;
export const Missing = () => question({ size: '1.5em' });
export const ModeDark = moonFill;
export const ModeLight = sunFill;
export const PageLinkOther = link45deg;  // Temporary icon! replaceme
