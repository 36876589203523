import React, { ReactNode, createContext, useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { TranslatedString } from "../common/language";
import * as utils from "../utils";

import Translatable from "../components/Translatable";
import { useLanguage } from "./LanguageContext";

const DialogContext = createContext<{
	state: null | Parms,
	setState: (s: null | Parms) => void,
}>({ state: null, setState: () => { } });

interface Parms {
	title: React.ReactNode,
	body: React.ReactNode,
	onClose: (rv: boolean) => void,
}

export function useDialog() {
	const { setState } = useContext(DialogContext) ?? utils.throwError(`'useDialog()' must be used within a 'DialogProvider'`);
	const { translate } = useLanguage();

	function fcnConfirm(p: {
		title?: TranslatedString,
	} & (
			{ message: TranslatedString, content?: never } |
			{ message?: never, content: React.ReactNode }
		)
	) {
		return confirm({
			translate,
			setState,
			title: p.title,
			content: (!('content' in p)) ? { message: p.message } : { content: p.content },
		})
	}

	return {
		confirm: fcnConfirm,
	}
}

export function DialogProvider({ children }: {
	children: ReactNode,
}) {
	const [state, setState] = useState<null | Parms>(null);
	return <DialogContext.Provider value={{ state, setState }}>
		{children}
		{(state != null) && <Dialog parms={state} />}
	</DialogContext.Provider>
}

function Dialog({ parms }: {
	parms: Parms,
}) {
	const { title, body, onClose } = parms;

	return (
		<Modal
			show
			size="lg"
			centered
			onHide={() => onClose(false)}
		>
			<Modal.Header closeButton>
				{title}
			</Modal.Header>
			<Modal.Body>
				{body}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onClose(false)}>
					<Translatable>{{
						fr: `Annuler`,
						nl: `Annuleren`,
					}}</Translatable>
				</Button>
				<Button variant="primary" onClick={() => onClose(true)}>
					<Translatable>{{
						fr: `Confirmer`,
						nl: `Bevestigen`,
					}}</Translatable>
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

async function confirm({ translate, title = { fr: 'Confirmation requise', nl: 'Bevestiging vereist' }, content, setState }: {
	translate: ReturnType<typeof useLanguage>['translate'],
	title?: TranslatedString,
	content: { message: TranslatedString } | { content: React.ReactNode },
	setState: (s: null | Parms) => void,
}) {
	try {
		return await new Promise((resolve: (rv: boolean) => void) => {
			const parms: Parms = {
				title: <Translatable>{title}</Translatable>,
				body: ('message' in content)
					? <>{translate(content.message)}</>
					: content.content,
				onClose: resolve,
			};
			setState(parms);
		});
	} finally {
		setState(null);
	}
}
