import { arrayToHashset, ensureEnum, nullOrWhitespaceTrim, queryUrl, strEnum, stringFilterMatches, throwError, UpdatedEventEmitter } from "./utils";
import { AdjustmentState, ItemState, ReferenceState } from "./itemRules";
import { Language, TranslatedString } from "./language";
import * as grp from "./groups";

export const { e: OrderBys, a: allOrderBys } = strEnum(['itemNumber', 'name', 'price']);
export type OrderBy = keyof typeof OrderBys;
export const orderByNames: { [k in OrderBy]: TranslatedString } = {
	'itemNumber': { fr: 'Numéro d\'article', nl: 'Artikelnummer' },
	'name': { fr: 'Nom', nl: 'Naam' },
	'price': { fr: 'Prix', nl: 'Prijs' },
}

/** Initial values of the parameters available to the filters */
class ParmBase {
	// Filters
	public itemNumber: string = '';
	public name: string = '';
	public groups: Set<grp.Group | grp.SubGroup> = new Set();

	// Order by
	public orderBy: OrderBy = 'itemNumber';
	public orderDesc: boolean = false;
	public errorsFirst: boolean = true;

	// Paging
	public page: number = 0;
	public pageSize: number = 10;
}

export type FilterBase = FilterBase_<ItemState>;

/** Common logic shared by all filters */
abstract class FilterBase_<TState extends ItemState> extends ParmBase {
	private _language: Language;
	private _allItems: TState[] = [];

	private computedRules: { lengthBeforePaging: number, filtered: TState[] } | null = null;
	public readonly computedRulesChanged = new UpdatedEventEmitter();

	public get totalPages(): number { return Math.ceil(this.getComputedRules().lengthBeforePaging / this.pageSize); }
	public get filteredRules(): TState[] { return this.getComputedRules().filtered; }
	public get hasFiltersActive(): boolean { return this.toUrlParms().length !== 0 }

	constructor(
		public readonly initial: ParmBase,
		language: Language,
		protected readonly handleChanges: () => void,
	) {
		super();
		this._language = language;
	}

	public get language(): Language {
		return this._language;
	}
	public set language(l: Language) {
		if (l === this._language)
			// noop
			return;
		this._language = l;

		this.computedRules = null;
		this.computedRulesChanged.trigger();
	}

	public get allItems(): TState[] {
		return this._allItems;
	}
	public set allItems(items: TState[]) {
		if (items === this._allItems)
			// noop
			return;
		this._allItems = items;

		this.computedRules = null;
		this.computedRulesChanged.trigger();
	}

	public fromUrlParms({ searchString, clearFirst = false }: { searchString: string, clearFirst?: boolean }) {
		const stateBefore = this.toUrlParms();

		if (clearFirst)
			this.clear();

		const parms = new URLSearchParams(searchString);
		parms.forEach((v, kk) => {
			const k = kk as keyof ParmBase;
			if (k === 'itemNumber')
				this.itemNumber = nullOrWhitespaceTrim(parms.get('itemNumber')) ?? this.itemNumber;
			if (k === 'name')
				this.name = nullOrWhitespaceTrim(parms.get('name')) ?? this.name;
			if (k === 'groups')
				this.groups = arrayToHashset(
					parms.get('groups')!.split(',') as (grp.Group | grp.SubGroup)[],
					(v) => grp.allCombinationSet.has(v));
			else if (k === 'orderBy')
				this.orderBy = ensureEnum(v, allOrderBys) ?? this.orderBy;
			else if (k === 'orderDesc')
				this.orderDesc = (ensureEnum(v, ['true', 'false']) ?? `${this.orderDesc}`) === 'true';
			else if (k === 'errorsFirst')
				this.errorsFirst = (ensureEnum(v, ['true', 'false']) ?? `${this.errorsFirst}`) === 'true';
			else if (k === 'page')
				this.page = parseInt(v);
			else if (k === 'pageSize')
				this.pageSize = parseInt(v);
			else
				this.restoreUrlParm(k, v);
		});

		this.fixPaging();

		const stateNow = this.toUrlParms();
		if (stateBefore !== stateNow) {
			this.computedRules = null;
			this.computedRulesChanged.trigger();
		}
	}
	protected abstract restoreUrlParm(key: string, value: string): void;

	public toUrlParms(): string {
		const parms: { [k: string]: string } = {};
		const self = this as { [key: string]: any };
		const initial = this.initial as { [key: string]: any };
		for (const key in initial) {
			let initialValue = initial[key];
			let newValue = self[key];
			if ((key as keyof ParmBase) === 'groups') {
				// Convert hashsets to strings
				initialValue = Array.from(this.initial.groups).sort().join(',');
				newValue = Array.from(this.groups).sort().join(',');
			}
			if (newValue !== initialValue)
				// nb: add to parameters only if they differ from initial
				parms[key] = newValue;
		}
		return queryUrl('', parms);
	}

	public clear() {
		const stateBefore = this.toUrlParms();

		Object.keys(this.initial).forEach((kk) => {
			const k = kk as keyof ParmBase;

			if (k === 'itemNumber')
				this.itemNumber = this.initial.name;
			else if (k === 'name')
				this.name = this.initial.name;
			else if (k === 'groups')
				this.groups = new Set(this.initial.groups);
			else if (k === 'orderBy')
				this.orderBy = this.initial.orderBy;
			else if (k === 'orderDesc')
				this.orderDesc = this.initial.orderDesc;
			else if (k === 'errorsFirst')
				this.errorsFirst = this.initial.errorsFirst;
			else if (k === 'page')
				this.page = this.initial.page;
			else if (k === 'pageSize')
				this.pageSize = this.initial.pageSize;
			else
				this.clearParm(k) || throwError(`Unable to clear parameter '${k}'`);
		});

		const stateNow = this.toUrlParms();
		if (stateBefore !== stateNow) {
			this.computedRules = null;
			this.computedRulesChanged.trigger();
		}
	}
	protected abstract clearParm(key: string): boolean;

	public apply(cb?: (self: this) => void): void {
		if (cb != null)
			cb(this);

		this.fixGroups();
		this.fixPaging();

		this.computedRules = null;
		this.handleChanges();
		this.computedRulesChanged.trigger();
	}

	private fixGroups(): void {
		if (this.groups.size === 0)
			// performance: quickly return when no filter
			return;

		const selectedSubGroupsPerGroups = {} as { [k in grp.Group]: Set<grp.SubGroup> };

		Array.from(this.groups)  // nb: work on a copy to allow modifications
			.forEach((v) => {
				let group = grp.allGroupsSet.has(v as grp.Group) ? v as grp.Group : null;
				const subGroup = grp.allSubGroupsSet.has(v as grp.SubGroup) ? v as grp.SubGroup : null;
				if (group !== null) {
					// this is a group
					selectedSubGroupsPerGroups[group] = selectedSubGroupsPerGroups[group] ?? new Set();
					return;
				}
				if (subGroup == null) {
					// this is an invalid value
					this.groups.delete(v);
					return;
				}
				// this is a subGroup
				group = grp.getGroup(subGroup)!;
				selectedSubGroupsPerGroups[group] = (selectedSubGroupsPerGroups[group] ?? new Set()).add(subGroup);

				// if a subgroup is selected, unselect its group
				this.groups.delete(group);
			});

		// if all subgroups of a group have been selected, select only the group
		Object.keys(selectedSubGroupsPerGroups).forEach((g) => {
			const group = g as grp.Group;
			const selectedSubGroups = selectedSubGroupsPerGroups[group];
			const allSubGroups = grp.subGroupsByGroups[group];
			if (selectedSubGroups.size !== allSubGroups.size)
				// ok, not all selected
				return;

			// select the group & unselect all its subgroups
			this.groups.add(group);
			allSubGroups.forEach((subGroup) => this.groups.delete(subGroup));
		});

		// if all groups have been selected, just clear the filter
		if (this.groups.size === grp.allGroups.length)
			if (!Array.from(this.groups).some((v) => !grp.allGroupsSet.has(v as grp.Group)))
				this.groups.clear();
	}

	private fixPaging(): void {
		if (this.pageSize < 2 || isNaN(this.pageSize))
			this.pageSize = 1;

		if ((this.page < 0) || isNaN(this.page))
			this.page = 0;
		else if (this.page >= this.totalPages)
			this.page = Math.max(this.totalPages - 1, 0);
	}

	private getComputedRules(): { lengthBeforePaging: number, filtered: TState[] } {
		if (this.computedRules != null)
			return this.computedRules;

		const filtered = this.allItems.filter(
			(rule) => this.filterPredicate(rule)
		);

		const ordered = filtered.sort(
			(a, b) => this.orderByPredicate(a, b)
		);

		const startIndex = this.page * this.pageSize;
		const endIndex = startIndex + this.pageSize;
		const paged = ordered.slice(startIndex, endIndex);

		this.computedRules = { lengthBeforePaging: ordered.length, filtered: paged };
		return this.computedRules;
	}

	protected filterPredicate(rule: TState): boolean {
		if (!stringFilterMatches(this.name, rule.item.name[this.language]))
			return false;
		if (!stringFilterMatches(this.itemNumber, rule.item.itemNumber))
			return false;
		if (this.groups.size > 0) {
			const inGroup = this.groups.has(grp.getGroup(rule.item.subGroup)!);
			const inSubGroup = this.groups.has(rule.item.subGroup!);
			if ((inGroup === false) && (inSubGroup === false))
				return false;
		}
		return true;
	}

	protected orderByPredicate(a: TState, b: TState): -1 | 0 | 1 {
		if (this.errorsFirst && (a.hasErrors() !== b.hasErrors())) {
			if (a.hasErrors()) return -1
			return 1
		}

		let fa: any;
		let fb: any;
		switch (this.orderBy) {
			case 'itemNumber':
				fa = parseInt(a.item.itemNumber);
				fb = parseInt(b.item.itemNumber);
				break;
			case 'name':
				fa = a.item.name[this.language];
				fb = b.item.name[this.language];
				break;
			case 'price':
				fa = a.item.price;
				fb = b.item.price;
				break;
			default:
				return 0;
		}
		if (fa < fb) return (this.orderDesc ? 1 : -1);
		if (fa > fb) return (this.orderDesc ? -1 : 1);
		return 0;
	}
}

export class ReferencesFilter extends FilterBase_<ReferenceState> {
	constructor(
		language: Language,
	) {
		const initial = new ParmBase();
		super(initial, language, () => { });
	}

	protected override restoreUrlParm(key: string, value: string): void { }
	protected override clearParm(key: string): boolean { return false; }
}

class AdjParmBase extends ParmBase {
	public adjFirst: boolean = true;
}
export class AdjustmentsFilter extends FilterBase_<AdjustmentState> implements AdjParmBase {
	public adjFirst: boolean = (new AdjParmBase().adjFirst);

	constructor(
		language: Language,
		allItems: AdjustmentState[] | null,
		handleChanges: () => void,
	) {
		const initial = new ParmBase();
		(initial as AdjustmentsFilter).adjFirst = true;
		super(new AdjParmBase(), language, handleChanges);
		if (allItems !== null)
			this.allItems = allItems;
	}

	protected override clearParm(kk: string): boolean {
		const k = kk as keyof AdjParmBase;
		if (k === 'adjFirst') {
			this.adjFirst = (this.initial as AdjParmBase).adjFirst;
			return true;
		}
		else {
			return false;
		}
	}

	protected override restoreUrlParm(key: string, value: string): void {
		const k = key as keyof AdjParmBase;
		switch (k) {
			case 'adjFirst':
				this.adjFirst = (ensureEnum(value, ['true', 'false']) ?? `${this.adjFirst}`) === 'true';
		}
	}

	protected override orderByPredicate(a: AdjustmentState, b: AdjustmentState): 0 | 1 | -1 {
		if (this.errorsFirst && (a.hasErrors() !== b.hasErrors()))
			// Errors take precedence
			return super.orderByPredicate(a, b);

		const aHasAdjustment = (a.adjustment != null);
		const bHasAdjustment = (b.adjustment != null);
		if (this.adjFirst && (aHasAdjustment !== bHasAdjustment)) {
			if (aHasAdjustment) return -1
			return 1;
		}

		return super.orderByPredicate(a, b);
	}
}
