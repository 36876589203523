import { useEffect, useState } from "react";

import Log from "./common/logger";
import { throwError, UpdatedEventEmitter } from "./common/utils";
export * from "./common/utils";

/** Ensure the parameter b is a boolean or throw an exception if not */
export function ensureBoolOrThrow({ b, log, errorMsg }: { b: any, log?: Log, errorMsg?: string }): boolean {
	switch (`${b}`.toLowerCase()) {
		case 'true':
		case '1':
			return true;
		case 'false':
		case '0':
			return false;
	}
	if (log)
		log.log(`Error parsing boolean value '${b}'`);
	throwError(errorMsg ?? `Invalid boolean specified`, log);
}

/** Ensure the parameter n is an integer or throw an exception if not */
export function ensureIntOrThrow({ n, log, errorMsg }: { n: any, log?: Log, errorMsg?: string }): number {
	const rc = parseInt(n);
	if ((!isNaN(rc)) && isFinite(rc))
		return rc;
	if (log)
		log.log(`Error parsing integer value '${n}'`);
	throwError(errorMsg ?? `Invalid number specified`, log);
}

/** Ensure the parameter s is a string or throw an exception if not */
export function ensureStringOrThrow({ s, log, errorMsg }: { s: any, log?: Log, errorMsg?: string }): string {
	if (s && (typeof (s) === 'string'))
		return s;
	if (log)
		log.log(`Invalid string value '${s}'`);
	throwError(errorMsg ?? `Invalid string specified`, log);
}

export function ensureInListOrThrow<T>({ v, l, log, errorMsg }: { v: any, l: T[], log?: Log, errorMsg?: string }): T {
	if ((v != null) && l.includes(v))
		return v;
	if (log)
		log.log(`Invalid value '${v}'`);
	throwError(errorMsg ?? `Invalid value specified`, log);
}

export async function shieldException<T>(
	log: Log,
	callback: (log: Log) => Promise<T>,
	finaly?: () => void,
) {
	try {
		return await callback(log);
	}
	catch (error) {
		log.exception(error);
		return 'error';
	} finally {
		if (finaly != null)
			finaly();
	}
}

/** Triggers a re-render whenever the specified event is emitted */
export function useRefreshOnChange(evt: UpdatedEventEmitter) {
	const [dummy, setDummy] = useState(evt.current);
	useEffect(() => {
		const unsubscribe = evt.subscribe((v) => {
			setDummy(v);
		});
		return () => unsubscribe();
	}, [evt]);
	return dummy;
}
